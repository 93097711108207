import { NextPage } from "next";
import { Button } from "@mui/material";
import { useRouter } from "next/router";
import SEO from "components/SEO";
import BazaarImage from "components/BazaarImage";
import { FlexBox, FlexRowCenter } from "components/flex-box";
import Head from "next/head";
import React from "react";

const Error404: NextPage = () => {
  const router = useRouter();
  const handleGoBack = () => router.back();
  const handleGoHome = () => router.push("/");

  return (
    <FlexRowCenter px={2} minHeight="100vh" flexDirection="column">
      <SEO title="페이지를 찾을 수 없습니다" />
      <Head>
        <meta name="robots" content="noindex,nofollow" />
      </Head>
      <BazaarImage
        src="/assets/images/illustrations/404.svg"
        sx={{ display: "block", maxWidth: 320, width: "100%", mb: 3 }}
      />

      <FlexBox flexWrap="wrap">
        <Button
          variant="outlined"
          color="primary"
          sx={{ m: 1 }}
          onClick={handleGoBack}
        >
          뒤로가기
        </Button>

        {/*<Button*/}
        {/*  variant="contained"*/}
        {/*  onClick={handleGoHome}*/}
        {/*  color="primary"*/}
        {/*  sx={{ m: 1 }}*/}
        {/*>*/}
        {/*  홈으로*/}
        {/*</Button>*/}
      </FlexBox>
    </FlexRowCenter>
  );
};

export default Error404;
